import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import productImage from "../assets/img/reactjscourse.jpg";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from 'react-i18next';


export const Course = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = ["Web Developer", "Product", "Web Design"];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }
  const [lang, setLang] = useState("la");
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang)
    localStorage.setItem("userLang", lang);
  };
  return (
    <section className="course" id="course">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <span className="tagline">ຄອ​ສສອນ React JS</span>

                  <p> React JS ສຳຫລັບສາຍ Font-end Developer ມືໃໝ່ ເລີ່ມຈາກ 0 ສຸດທີ່ລະດັບ Pre-Junior ບໍ່ມີພື້ນຖານກໍ່ສາມາດຮຽນໄດ້.</p>
                  <p>ຕົວຢ່າງເນື້ອຫາການຮຽນໃນເບື້ອງຕົ້ນ:</p>
                  <p>1. ການສ້າງ project ດ້ວຍ library vite ແລະ npx (v18)</p>
                  <p>2. ການສ້າງ Component ໃນຮູບແບບຂອງ functional ທີ່ສາມາດເອົາມານຳໃຊ້ຄືນໄດ້ພາຍໃນໂປຣເຈັກ</p>
                  <p>3. ການນຳໃຊ້ props ແລະ state ໃນການສົ່ງຄ່າ ແລະ ຮັບຄ່າ ໃນຫລາຍໆຮູບແບບເຊັ່ນ: array, object , string, int ແລະ ອື່ນໆ.. </p>
                  <p>4. ການນຳໃຊ້ react hook ເຊັ່ນ: useState, useEffect, useCallback ແລະ ອື່ນໆ...</p>
                  <p>5. ການສ້າງ route ທີ່ເປັນ publich route ແລະ private route ( react-route-dom v16 ) ແລະ ການສົ່ງຄ່າຜ່ານ route ໃນຮູບແບບແບບຂອງ param ແລະ location</p>
                  <p>6. Work shop ການເຮັດ ສ້າງ, ເພີ່ມ, ແກ້ໄຂ ແລະ ສະແດງ (CRUD) ຕໍ່ກັບ API ໃນຮູບແບບຂອງ Rest API ແລະ Graphql ອື່ນໆ...</p>
                </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={4}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__zoomIn" : ""}>
                  <img src={productImage} alt="Product Img" />
                </div>}
            </TrackVisibility>
          </Col>


        </Row>
      </Container>
    </section>
  )
}

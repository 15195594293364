import { Container, Row, Col, Tab} from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/document_management.JPG";
import projImg2 from "../assets/img/stock_management.JPG";
import projImg3 from "../assets/img/budget_management.JPG";
import projImg4 from "../assets/img/registration_management.JPG";
import projImg5 from "../assets/img/online_platform.JPG";
import projImg6 from "../assets/img/education_management.JPG";
import projImg7 from "../assets/img/companyWebsite.png";
import projImg8 from "../assets/img/organizationWeb.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import { useState} from "react";
import { useTranslation } from 'react-i18next';

import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Document management",
      description: "ລະ​ບົບ​ບັນ​ທຶກ​ເອ​ກະ​ສານ​ຂາ​ເຂົ້າ-ອອກ",
      imgUrl: projImg1,
    },
    {
      title: "Stock Management",
      description: "ລະ​ບົບ​ຈັດ​ການ​ຂໍ້​ມູນ​ຄົນ​ເຈັບ ແລະ ສະ​ຕັອກ​ຢາ",
      imgUrl: projImg2,
    },
    {
      title: "Budget management",
      description: "ລະ​ບົບ​ບໍ​ລິ​ຫານ​ງົບ​ປະ​ມານ",
      imgUrl: projImg3,
    },
    {
      title: "Registration System",
      description: "ລະ​ບົບ​​ລົງ​ທະ​ບຽນ​ຮຽນ",
      imgUrl: projImg4,
    },
    {
      title: "Online Course System",
      description: "ລະ​ບົບ​ການ​ຮຽນ​ອອນ​ລາຍ",
      imgUrl: projImg5,
    },
    {
      title: "School Management",
      description: "ລະ​ບົບ​ການ​ບໍ​ລ​ິຫານ​ການສຶກ​ສາ",
      imgUrl: projImg6,
    },
    {
      title: "Company Website",
      description: "ເວັບໄຊບໍລິສັດ",
      imgUrl: projImg7,
    },
    {
      title: "Organization Website",
      description: "ເວັບໄຊອົງກອນ",
      imgUrl: projImg8,
    },
  ];

  const [lang, setLang] = useState("la");
  const { t, i18n } = useTranslation();

  const changeLanguageHandler = (lang) => {
    i18n.changeLanguage(lang);
    setLang(lang)
    localStorage.setItem("userLang", lang);
  };

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>{t("previousProject")}</h2>
                <p>{t("previousProjectContent")}</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
